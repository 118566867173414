import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "topQuery", "query", "form"
  ]
  
  topFormSubmit(event) {
    if (this.hasFormTarget) {
      event.preventDefault()
      this.queryTarget.value = this.topQueryTarget.value
      this.submit()
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
