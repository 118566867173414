import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="variant-price"
export default class extends Controller {
  static targets = ["submit"]
  
  focused(_event) {
    this.submitTarget.classList.remove("invisible")
  }
}
