import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

function targetToFloat(target) {
  return parseFloat(target.value)
}

export default class extends Controller {
  static targets = [
    "imageResult", "form", "xOffsetResult", "yOffsetResult", "widthResult", "heightResult",
    "width", "height",
    "numerator", "denominator"
  ]

  connect() {
    this.render()
  }

  render() {
    const imageResult = this.imageResultTarget
    if (imageResult.cropper) imageResult.cropper.destroy()

    const xOffsetResult = this.xOffsetResultTarget
    const yOffsetResult = this.yOffsetResultTarget
    const widthResult = this.widthResultTarget
    const heightResult = this.heightResultTarget

    const height = targetToFloat(this.heightTarget)
    const width = targetToFloat(this.widthTarget)
    const numerator = targetToFloat(this.numeratorTarget)
    const denominator = targetToFloat(this.denominatorTarget)

    let aspectRatio = null
    if (height > width) {
      aspectRatio = denominator / numerator
    } else {
      aspectRatio = numerator / denominator
    }

    new Cropper(imageResult, {
      scalable: false,
      zoomable: false,
      movable: false,
      aspectRatio,
      autoCropArea: 1,
      crop(event) {
        xOffsetResult.value = event.detail.x
        yOffsetResult.value = event.detail.y
        widthResult.value = event.detail.width
        heightResult.value = event.detail.height
      }
    })
  }
}
