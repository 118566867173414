import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="upload-image"
export default class extends Controller {
  static targets = [
    "form", "imageInput",
    "xOffset", "yOffset", "width", "height",
    "xOffsetResult", "yOffsetResult", "widthResult", "heightResult",
    "displayImage", "placeholder",
    "cropImage",
    "cropButton"
  ]

  crop(e) {
    e.preventDefault()
    
    this.xOffsetTarget.value = this.xOffsetResultTarget.value
    this.yOffsetTarget.value = this.yOffsetResultTarget.value
    this.widthTarget.value = this.widthResultTarget.value
    this.heightTarget.value = this.heightResultTarget.value
    this.continue()
  }

  continue() {
    const form = this.formTarget
    const imageInput = this.imageInputTarget
    if (imageInput.files.length > 0) {
      form.requestSubmit()
    }
  }
  
  selected(event) {
    const file = event.target.files[0]
    const cropImage = this.cropImageTarget
    const displayImage = this.displayImageTarget
    const placeholder = this.placeholderTarget
    const cropButton = this.cropButtonTarget
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        cropImage.src = e.target.result;
        displayImage.src = e.target.result;
        displayImage.classList.remove("hidden");
        displayImage.classList.add("block");
        placeholder.classList.add("hidden");
        cropButton.classList.remove("hidden");
        cropButton.classList.add("flex");
      };
      reader.readAsDataURL(file);
    } else {
      displayImage.classList.add("hidden");
      placeholder.classList.remove("hidden");
      placeholder.classList.add("block");
      cropButton.classList.remove("flex");
      cropButton.classList.add("hidden");
    }
  }
}
