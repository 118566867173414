import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["passwordInput", "eyeIcon", "closedEyeIcon"]

  toggleVisibility() {
    const passwordInput = this.passwordInputTarget
    const eyeIcon = this.eyeIconTarget
    const closedEyeIcon = this.closedEyeIconTarget

    if (passwordInput.type === 'password') {
      passwordInput.type = 'text'
      eyeIcon.classList.add('hidden')
      closedEyeIcon.classList.remove('hidden')
    } else {
      passwordInput.type = 'password'
      eyeIcon.classList.remove('hidden')
      closedEyeIcon.classList.add('hidden')
    }
  }
}
