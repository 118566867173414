import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navigation", "overlay"];

  toggleDrawer() {
    this.navigationTarget.classList.toggle("open");
    this.overlayTarget.classList.toggle("show");
  }
}
