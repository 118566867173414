import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-button"
export default class extends Controller {
  static targets = ["button", "checkbox"]

  change() {
    const checked = this.checkboxTarget.checked

    if (checked) {
      this.buttonTarget.classList.remove("bg-transparent")
      this.buttonTarget.classList.remove("border-white-2")
      this.buttonTarget.classList.add("bg-purple-2")
      this.buttonTarget.classList.add("border-purple-1")
    } else {
      this.buttonTarget.classList.add("bg-transparent")
      this.buttonTarget.classList.add("border-white-2")
      this.buttonTarget.classList.remove("bg-purple-2")
      this.buttonTarget.classList.remove("border-purple-1")
    }
  }
}
