import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { content: String, inputSelector: String }

  copy() {
    let value;
    if (this.contentValue) {
      value = this.contentValue;
    } else {
      if (this.inputSelectorValue) {
        value = document.querySelector(this.inputSelectorValue).value;
      }
    }
    navigator.clipboard.writeText(value)
  }

  paste() {
    navigator.clipboard.readText()
  }
}
