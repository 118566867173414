import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="signup-with-email"
export default class extends Controller {
  static targets = ["fullNameInput", "emailInput", "passwordInput", "accceptedTermsAndConditionsInput", "submitInput"]

  validate() {
    const password = this.passwordInputTarget.value;
    const email = this.emailInputTarget.value;
    const fullName = this.fullNameInputTarget.value;
    const acceptedTermsAndConditions = this.accceptedTermsAndConditionsInputTarget.checked;

    // FIMME: Use zod, validate email
    let valid = false;
    if (password.length > 0 && email.length > 0 && fullName.length > 0 && acceptedTermsAndConditions) {
      valid = true;
    }

    if (valid) {
      this.submitInputTarget.disabled = false;
    } else {
      this.submitInputTarget.disabled = true;
    }
  }
}
