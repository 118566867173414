import { Controller } from "@hotwired/stimulus";

export default class TrixController extends Controller {
  static UNUSED_TOOLBAR_CLASSES = [
    ".trix-button-group--file-tools",
    ".trix-button-group--history-tools",
    ".trix-button--icon-link",
    ".trix-button--icon-code",
    ".trix-button--icon-number-list"
  ];

  // static TOOLBAR_BUTTON_ICONS = [
    // { identifier: "trix-button--icon-bold", icon: "<svg ....></svg>"},
    // { identifier: "trix-button--icon-italic", icon: "<svg ....></svg>"},
    // { identifier: "trix-button--icon-underline", icon: "<svg ....></svg>"},
  // ]

  connect() {

    // wait for the trix editor is attached to the DOM to do stuff
    addEventListener("trix-initialize", function (event) {
      // FIXME: underline doesn't save!
      
      // initialize underline attribute
      Trix.config.textAttributes.underline = {
        tagName: "u",
        style: { textDecoration: "underline" },
        class: "text-lg",
        inheritable: true,
        parser: function (element) {
          var style = window.getComputedStyle(element);
          return style.textDecoration === "underline";
        },
      };

      // create underline button
      let underlineEl = document.createElement("button");
      underlineEl.setAttribute("type", "button");
      underlineEl.setAttribute("data-trix-attribute", "underline");
      underlineEl.setAttribute("data-trix-key", "u");
      underlineEl.setAttribute("tabindex", -1);
      underlineEl.setAttribute("title", "underline");
      underlineEl.classList.add("trix-button", "trix-button--icon-underline");
      underlineEl.innerHTML = "U";

      // add button to toolbar
      document.querySelector(".trix-button-group--text-tools").appendChild(underlineEl);

      // remove unused toolbar buttons
      TrixController.UNUSED_TOOLBAR_CLASSES.forEach((cls) => {
        document.querySelector(cls).remove();
      });

      // update toolbar icons
      // TrixController.TOOLBAR_BUTTON_ICONS.forEach((group) => {
      //   document.querySelector(group.identifier).innerHTML = group.icon;
      // });
    }, true);

    // remove file upload handling
    addEventListener("trix-file-accept", function (event) {
      event.preventDefault();
    }, true);
  }
}
